import { IPSTACK_ACCESS_KEY, IPSTACK_URL } from "../../constants/APIKeys";
import { pageCountry, userData } from "../../graphql/reactivities";

export const getPhoneLocale = async (countries, allCountries = false) => {
  const activeCountry = pageCountry();
  const { phone } = userData();

  let hasCity;

  if (!allCountries && phone) {
    if (activeCountry) {
      hasCity = countries.find(({ slug }) => slug === activeCountry);

      if (hasCity) {
        const { alpha2, phoneCode } = hasCity;

        if (phone.includes(phoneCode)) return alpha2.toLowerCase();
      }
    }

    // если нужно выбрать только одну страну по умолчанию

    // const [{ alpha2, slug }] = countries;
    // const countrySlug = slug.toLowerCase();

    // pageCountry(countrySlug);
    // localStorage.setItem("pageCountry", JSON.stringify(countrySlug));

    // return alpha2.toLowerCase();
  }

  const userCountry = {};

  await fetch(`${IPSTACK_URL}${IPSTACK_ACCESS_KEY}`)
    .then((response) => response.json())
    .then((data) => {
      const {
        latitude: IPLatitude = 0,
        longitude: IPLongitude = 0,
        country_code: country_code_high, //pl
        location: { calling_code = "380" },
      } = data;

      const country_code = country_code_high?.toLowerCase();

      // if (phone?.includes(calling_code)) return { country_code };

      // if (hasCity && allCountries) return { country_code };

      userCountry.country_code = country_code;

      userCountry.calling_code = calling_code;

      // если нужно выбрать только одну страну по умолчанию
      let shortestDistance = Infinity;

      for (const { latitude = 0, longitude = 0, slug, alpha2 } of countries) {
        if (country_code === alpha2?.toLowerCase()) {
          userCountry.alpha2 = alpha2.toLowerCase(); //pl
          userCountry.slug = slug.toLowerCase(); //poland
          break;
        }

        const distance = Math.sqrt(Math.pow(latitude - IPLatitude, 2) + Math.pow(longitude - IPLongitude, 2));

        if (distance < shortestDistance) {
          shortestDistance = distance;

          userCountry.alpha2 = alpha2.toLowerCase();
          userCountry.slug = slug.toLowerCase();
        }
      }
    })
    .catch((_) => {
      const [{ alpha2, slug }] = countries;
      userCountry.alpha2 = alpha2.toLowerCase();
      userCountry.slug = slug.toLowerCase();
    });

  const { alpha2, slug, country_code, calling_code } = userCountry;
  //calling_code  = 380
  //country - pl
  //phone - +90
  if (!hasCity) {
    pageCountry(slug);
    localStorage.setItem("pageCountry", JSON.stringify(slug));
  }

  if (allCountries || phone?.includes(calling_code)) return country_code;

  // if (phone) {
  //   if (phone?.includes(calling_code)) {
  //     phoneCountry(country_code);

  //     return country_code;
  //   }

  //   if (activePhoneCountry) {
  //     return activePhoneCountry;
  //   }
  // }

  return alpha2;
};
